// material design recommended icon sizes
$sizes: 18 24 36 48;
@each $size in $sizes {
    .#{$mdi-css-prefix}-#{$size}px {
        &.#{$mdi-css-prefix}-set,
        &.#{$mdi-css-prefix}:before {
            font-size: $size * 1px; 
        }
    }
}

// material design recommended icon opacity
.#{$mdi-css-prefix}-dark {
    color: rgba(0, 0, 0, 0.54);
    &.mdi-inactive {
        color: rgba(0, 0, 0, 0.26);
    }
}
.#{$mdi-css-prefix}-light {
    color: rgba(255, 255, 255, 1);
    &.mdi-inactive {
        color: rgba(255, 255, 255, 0.3);
    }
}

// rotation
$degrees: 45 90 135 180 225 270 315;
@each $degree in $degrees {
    .#{$mdi-css-prefix}-rotate-#{$degree} {
        -webkit-transform: rotate(#{$degree}deg);
        -ms-transform: rotate(#{$degree}deg);
        transform: rotate(#{$degree}deg);
    }
}
.#{$mdi-css-prefix}-flip-horizontal {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
}
.#{$mdi-css-prefix}-flip-vertical {
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: "FlipV";
}
